<template lang="pug">
.create-matrix__body
  v-dropdown(:focusSwitchEL="focusSearch")
    v-input-search.input-search(
      :placeholder="'ui.inputs.type_to_search_employees_departments_locations' | translate",
      type="text",
      :auto-focus="false",
      @focus="focusSearch = $event",
      v-model="search"
    )
    template(slot="content")
      v-card(elevation)
        v-scrollable(
          updateble,
          :min-height="20",
          :bottom-offset="15",
          @scroll-complete="onNextPage"
        )
          template(v-if="loaded")
            template(v-if="hybridSearchData.length")
              v-list
                v-list-item(
                  v-for="item in hybridSearchData",
                  :key="item.id",
                  @click="!selectedUsers.find((el) => el.item.id === item.item.id && item.typeId === el.typeId) ? selectEmployee(item) : removeEmployee(item)"
                )
                  slot(name="icon")
                    v-avatar(
                      v-if="item.typeId === searchTypes.EMPLOYEE",
                      :size="48",
                      :src="item.item.profilePictureUrl"
                    )
                    .avatar(v-else)
                      icon-department.m-0(
                        v-if="item.typeId === searchTypes.DEPARTMENT"
                      )
                      icon-location.m-0(v-else)
                  v-list-item-content
                    v-list-item-title
                      span {{ item.typeId === searchTypes.EMPLOYEE ? item.item.firstName + ' ' + item.item.lastName : item.item.name }}
                    v-list-item-subtitle(
                      v-if="(item.item.position && item.item.position.name) || (item.item.department && item.item.department.name)"
                    )
                      span {{ (item.item.position && item.item.position.name) || (item.item.department && item.item.department.name) }}
                  v-list-item-actions
                    v-btn(
                      v-if="!selectedUsers.find((el) => el.item.id === item.item.id && item.typeId === el.typeId)",
                      icon,
                      success
                    )
                      icon-plus
                    v-btn(v-else, icon, danger)
                      icon-minus
            .search-result__empty(v-else)
              v-list-empty(v-if="search.length && !loadingSearch")
                i.icon.icon--sm.icon--no-result
                span {{ 'ui.labels.no_results' | translate }}
              employee-item-skeleton(v-else-if="loadingSearch", :count="3")
              v-list-empty(v-else)
                i.icon.icon--sm.icon--empty
                span {{ 'ui.labels.list_is_empty' | translate }}
          employee-item-skeleton(v-else, :count="3")
          employee-item-skeleton(small, :count="skeletons")
  v-list-empty.create-matrix__result(v-if="!selectedUsers.length")
    img(:src="imgSelectEmployees", alt="image Select Employees")
  v-scrollable.create-matrix__content(v-else, updateble, :min-height="20")
    .create-matrix__baguettes
      v-baguette(
        v-for="(item, index) in selectedUsers",
        :key="index + 'e'",
        :color="selectionСolorBaguette(item.typeId)",
        :background-color="selectionBgBaguette(item.typeId)"
      )
        icon-employees.m-0(v-if="item.typeId === searchTypes.EMPLOYEE")
        icon-department.m-0(v-else-if="item.typeId === searchTypes.DEPARTMENT")
        icon-location.m-0(v-else)
        span {{ item.typeId === searchTypes.EMPLOYEE ? item.item.firstName + ' ' + item.item.lastName : item.item.name }}
        v-btn(paddingless, text, @click="removeEmployee(item)")
          icon-close.m-0
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'
import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'
import { HYBRID_SEARCH_TYPES } from '@/util/constants'
import imgSelectEmployees from '@/assets/img/skill-matrix/select-employees.svg'
import IconPlus from '@/components/svg/IconPlusAddToList.vue'
import IconMinus from '@/components/svg/icons/IconMinus.vue'
import IconClose from '@/components/svg/VInputSearch_CloseIcon'
import IconEmployees from '@/components/svg/skill-matrix/IconEmployees.vue'
import IconLocation from '@/components/svg/skill-matrix/IconLocation.vue'
import IconDepartment from '@/components/svg/skill-matrix/IconDepartment.vue'

export default {
  name: 'SelectEmployeesStep',
  components: {
    EmployeeItemSkeleton,
    IconPlus,
    IconMinus,
    IconClose,
    IconEmployees,
    IconLocation,
    IconDepartment
  },
  mixins: [scrollPaginationSkeletonsMixin],

  data: () => ({
    selectedUsers: [],
    search: '',
    loaded: false,
    loadingSearch: true,
    imgSelectEmployees,
    focusSearch: false
  }),

  computed: {
    ...mapGetters('search', ['hybridSearchData', 'hybridPaginationParams']),

    searchTypes () {
      return HYBRID_SEARCH_TYPES
    },

    selected () {
      return {
        employees: this.selectedUsers
          .filter((el) => el.typeId === this.searchTypes.EMPLOYEE)
          .map((el) => el.item),
        locations: this.selectedUsers
          .filter((el) => el.typeId === this.searchTypes.LOCATION)
          .map((el) => el.item),
        departments: this.selectedUsers
          .filter((el) => el.typeId === this.searchTypes.DEPARTMENT)
          .map((el) => el.item)
      }
    }
  },

  async mounted () {
    await this.hybridSearch({ params: { noBadges: 1, noPositions: 1 } })
    this.initScrollPagination(
      { pagination: this.hybridPaginationParams },
      this.loadNextPage
    )
    this.loadingSearch = false
    this.loaded = true
  },
  methods: {
    ...mapActions('search', ['hybridSearch']),
    selectEmployee (item) {
      this.selectedUsers.push(item)
    },

    removeEmployee (item) {
      const index = this.selectedUsers.findIndex(
        (el) => el.item.id === item.item.id
      )
      this.selectedUsers.splice(index, 1)
    },
    async loadNextPage () {
      const page = this.hybridPaginationParams.page
        ? this.hybridPaginationParams.page + 1
        : 1
      const params = {
        page,
        noBadges: 1,
        noPositions: 1
      }
      if (this.hybridPaginationParams.q) {
        params.q = this.hybridPaginationParams.q
      }
      await this.hybridSearch({ params, isPushed: true })
    },

    async onSearch (search) {
      this.loadingSearch = true
      const params = {
        q: search,
        page: 1,
        noBadges: 1,
        noPositions: 1
      }
      await this.hybridSearch({ params })
      this.loadingSearch = false
    },

    selectionСolorBaguette (type) {
      return type === this.searchTypes.EMPLOYEE
        ? '#29A687'
        : type === this.searchTypes.DEPARTMENT
        ? '#BB6BD9'
        : '#0082C0'
    },
    selectionBgBaguette (type) {
      return type === this.searchTypes.EMPLOYEE
        ? '#E3F6F1'
        : type === this.searchTypes.DEPARTMENT
        ? '#F4E7F9'
        : '#D7EBF4'
    }
  },

  watch: {
    search (value) {
      this.onSearch(value)
    },
    selected (value) {
      this.$emit('select', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.create-matrix {
.v-card{
  padding: 8px 4px 8px 16px;
}
.v-list{
  padding-right: 20px;
}
.v-list__item {
    padding: 12px 8px 11px;
    .v-avatar {
      margin-right: 16px;
    }
    .avatar {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 16px;
      background-color: $color-light-green;
      ::v-deep svg {
        color: $color-white;
        width: 24px;
        height: 24px;
      }
    }
    &-title,
    &-subtitle {
      font-size: 14px;
      line-height: 16px;
      color: $body-text;
    }
    &-title {
      font-weight: 700;
    }
    &-subtitle {
      font-weight: 400;
      margin-top: 4px;
    }
    ::v-deep .v-btn {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    flex: 1 0 auto;
    margin-top: 16px;
    max-height: 324px;
  }

  &__result {
    height: 100%;
    padding: 0;
  }
}
</style>

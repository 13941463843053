import { render, staticRenderFns } from "./IconLocation.vue?vue&type=template&id=371e4c9a&scoped=true&functional=true&"
import script from "./IconLocation.vue?vue&type=script&lang=js&"
export * from "./IconLocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "371e4c9a",
  null
  
)

export default component.exports
<template functional>
<svg  :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.01" width="16" height="16" fill="none"/>
  <path d="M14.3579 10.9972C14.4574 11.2047 14.44 11.4479 14.3216 11.6452C13.2223 13.4782 11.3366 14.7899 9.11204 15.1209C8.74519 15.1773 8.37835 15.1999 8.00004 15.1999C7.62174 15.1999 7.24343 15.1661 6.88805 15.1209C4.66267 14.7897 2.76623 13.4773 1.67571 11.6433C1.55897 11.447 1.54206 11.2059 1.64015 10.9996C2.18396 9.85608 3.2347 8.98184 4.50562 8.66969C4.72311 8.61628 4.94839 8.68135 5.1315 8.81029C5.80926 9.28756 6.54496 9.59163 7.45004 9.6609C7.56468 9.67219 7.75004 9.68298 7.90004 9.68298C8.05004 9.68298 8.18955 9.6717 8.35004 9.66042C9.22719 9.58104 10.0824 9.27478 10.7749 8.80302C10.9555 8.68 11.1735 8.61271 11.387 8.65893C12.7051 8.9442 13.7982 9.82969 14.3579 10.9972Z" fill="currentColor"/>
  <path d="M12 4.80005C12 7.0085 10.2085 8.80005 8 8.80005C5.79155 8.80005 4 7.0085 4 4.80005C4 2.6592 5.67887 0.912725 7.79718 0.800049C7.81972 0.800049 7.85352 0.800049 7.87606 0.800049C7.92113 0.800049 7.9662 0.800049 8.01127 0.800049C8.04507 0.800049 8.07887 0.800049 8.11268 0.800049C10.2648 0.856387 12 2.6254 12 4.80005Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconEmployees'
}
</script>

<style scoped></style>

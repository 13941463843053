import { render, staticRenderFns } from "./IconEmployees.vue?vue&type=template&id=7949987d&scoped=true&functional=true&"
import script from "./IconEmployees.vue?vue&type=script&lang=js&"
export * from "./IconEmployees.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "7949987d",
  null
  
)

export default component.exports
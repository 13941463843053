<template functional>
<svg  :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.01" width="16" height="16" fill="white"/>
  <path d="M8 0.799927C4.69 0.799927 2 3.48993 2 6.79993C2 8.42993 2.65 9.89993 3.7 10.9799L7.33955 14.9115C7.69577 15.2963 8.30423 15.2963 8.66045 14.9115L12.3 10.9799C13.35 9.89993 14 8.42993 14 6.79993C14 3.48993 11.31 0.799927 8 0.799927ZM8 9.39993C6.56 9.39993 5.4 8.23993 5.4 6.79993C5.4 5.35993 6.56 4.19993 8 4.19993C9.44 4.19993 10.6 5.35993 10.6 6.79993C10.6 8.23993 9.44 9.39993 8 9.39993Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconLocation'
}
</script>

<style scoped></style>
